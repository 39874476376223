import {
  Box,
  Content,
  Logo,
  Sidebar,
  SidebarLayout
} from '@bonobolabs/mds-web-ui-gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

import SEO from '../SEO'
// import NavDropdown from './Nav/dropdown'
import DocsContent from './Content'
import { DocsSearch } from './DocsSearch'
import Nav from './Nav'
import { SkipToContent } from './SkipLink'
import { useScrollRestoration } from './useScrollRestoration'

export const DocsContainer = ({
  category,
  section,
  excerpt,
  title,
  description,
  slug,
  image,
  children
}) => {
  const { ref: scrollRef, onScroll } = useScrollRestoration(slug)

  return (
    // Layout
    <SidebarLayout bg="white">
      <Helmet title={`${title} · ${category.title}`} />
      <SEO
        title={`${title} · ${category.title}`}
        description={description ? description : excerpt}
        image={image}
        path={slug}
        isArticle={true}
      />
      <SkipToContent />
      <Sidebar>
        <Box css={{ alignSelf: 'center' }} pl={14} pr={3}>
          <Logo />
        </Box>
        <Nav slug={slug} category={category} section={section} title={title} />
      </Sidebar>
      <Content
        showFooter
        showHelp
        currentSite="home"
        pt={[3, 4]}
        px={[3, 3, 4, 5]}
        ref={scrollRef}
        mainProps={{ onScroll }}
      >
        {children}
      </Content>
    </SidebarLayout>
  )
}

DocsContainer.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
  }),
  category: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  section: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
}

const Docs = ({ children, title, section, tableOfContents, slug }) => (
  // category > section > item
  <>
    <DocsSearch />
    <DocsContent
      title={title}
      slug={slug}
      baseURL="support"
      section={section.title}
      tableOfContents={tableOfContents}
    >
      {children}
    </DocsContent>
  </>
)

Docs.propTypes = {
  title: PropTypes.string.isRequired,
  section: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  tableOfContents: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string
      })
    )
  })
}

export default Docs
